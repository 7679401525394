import * as React from 'react';
import { useRef, useEffect } from 'react';
import classnames from 'classnames';
import XIcon from '../../icons/x.svg';
import useMessages from '../../hooks/useMessages';

type InputLabelProps = {
  id?: string,
  label?: string,
  inline?: boolean,
  showReset?: boolean,
  resetButtonText?: string,
  onReset?: () => void,
  className?: string,
  autofocus?: boolean,
};

const InputLabel = ({
  id, label, inline, showReset, resetButtonText, onReset, children, className, autofocus,
}: React.PropsWithChildren<InputLabelProps>) => {
  const getMessage = useMessages();
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autofocus) {
      labelRef.current?.focus();
    }
  }, [autofocus]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className="flex justify-between" ref={labelRef} tabIndex={autofocus ? 0 : undefined}>
      <label
        className={classnames(
          'flex justify-between my-1 text-text-lg-semibold text-typography-black',
          { 'inline-block mr-3': inline },
          className,
        )}
        htmlFor={id}
      >
        <span className="inline-block">
          {children || label}
        </span>
      </label>
      {showReset && (
        <button
          onClick={onReset}
          type="button"
          className="flex items-center text-text-sm-medium text-typography-dark hover:text-hover"
        >
          <span>{resetButtonText || getMessage('reset')}</span>
          <XIcon className="ml-1 mobile:w-4 mobile:h-4" />
        </button>
      )}
    </div>
  );
};

const InputError = ({ message }: { message: string }) => (
  <div className="text-interactions-input-error bg-transparent my-1 text-text-md-medium">
    {message}
  </div>
);

export {
  InputLabel, InputError,
};
