import * as React from 'react';
import { useIntl } from 'react-intl';
import Dialog from '../../../components/Dialog/Dialog';
import useMessages from '../../../hooks/useMessages';

type Props = {
  isOpen: boolean,
  number: string | number,
  onCancel: () => void,
  onConfirm: () => void
};

const ArchiveAnnouncementPopup = ({
  isOpen, number, onCancel, onConfirm,
}: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();
  return (
    <Dialog
      isOpen={isOpen}
      onAccept={onConfirm}
      onClose={onCancel}
    >
      <h3 className="text-xl font-semibold text-center">
        { intl.formatMessage({ id: 'announcements.announcement.archivePopup.title' }, { announcementNumber: number }) }
      </h3>
      <p className="mb-12 mt-8 mx-16 text-sm text-typography-dark">
        { getMessage('announcements.announcement.archivePopup.description') }
      </p>
    </Dialog>
  );
};

export default ArchiveAnnouncementPopup;
