import * as React from 'react';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import LogoutIcon from '../../../icons/logout.svg';
import useMessages from '../../../hooks/useMessages';
import { useGetFullUserAccountQuery, useLogoutMutation } from '../../../api/appApi';
import { selectVenue, VenueSelectPayload } from '../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';

interface Props {
  isOpen: boolean,
}

const UserNavigation = React.forwardRef(({ isOpen }: Props, ref: React.Ref<any>) => {
  const getMessage = useMessages();
  const [logout, logoutResult] = useLogoutMutation();
  const { data: user, isSuccess: userFetched } = useGetFullUserAccountQuery();
  const dispatch = useAppDispatch();
  const selectedVenueId = useAppSelector((state) => state.app.selectedVenueId);
  const venues = useMemo(() => user?.tenant?.venues, [user]);

  useEffect(() => {
    if (logoutResult.status === 'fulfilled') {
      window.location.href = '/accounts/sign_in';
    }
  }, [logoutResult]);

  useEffect(() => {
    if (userFetched && venues) {
      let preselectedVenue: VenueSelectPayload;

      if (localStorage.getItem(`selectedVenueName-${user.id}`)) {
        preselectedVenue = {
          userId: user.id,
          selectedVenueId: localStorage.getItem(`selectedVenueId-${user.id}`) || '',
          selectedVenueName: localStorage.getItem(`selectedVenueName-${user.id}`) || '',
        };
      } else if (venues.length === 1) {
        preselectedVenue = {
          userId: user.id,
          selectedVenueId: venues[0].id,
          selectedVenueName: venues[0].address,
        };
      } else {
        preselectedVenue = {
          userId: user.id,
          selectedVenueId: '',
          selectedVenueName: getMessage('navigation.allVenues'),
        };
      }
      dispatch(selectVenue(preselectedVenue));
    }
  }, [userFetched]);

  const handleKeyLogout = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      logout(null);
    }
  };

  const handleKeySelect = (e: React.KeyboardEvent<HTMLDivElement>, venuePayload: VenueSelectPayload) => {
    if (e.key === 'Enter' || e.key === ' ') {
      dispatch(selectVenue(venuePayload));
    }
  };

  if (!userFetched) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={classNames(
        'absolute transition-height duration-500 ease-in-out z-20 bg-background-light lg:bg-background-white'
        + ' w-full overflow-hidden flex flex-col justify-between text-text-lg-medium',
        { 'h-sidebar visible': isOpen, hidden: !isOpen },
      )}
    >
      <div className="w-full px-2 sm:px-3 md:px-7 flex flex-col py-4 space-y-1 overflow-y-auto">
        {venues?.map((venue) => (
          <div
            key={venue.id}
            className={
              `p-2 cursor-pointer hover:underline hover:text-hover rounded 
              ${selectedVenueId === venue.id ? 'text-hover bg-light-200' : 'text-typography-dark'}`
            }
            role="button"
            onClick={() => dispatch(selectVenue({
              userId: user.id,
              selectedVenueId: venue.id,
              selectedVenueName: venue.address,
            }))}
            tabIndex={0}
            onKeyPress={(e) => handleKeySelect(e, {
              userId: user.id,
              selectedVenueId: venue.id,
              selectedVenueName: venue.address,
            })}
          >
            {venue.address}
          </div>
        ))}
        {venues?.length && venues?.length > 1
          && (
          <div
            className={
              `p-2 cursor-pointer hover:underline hover:text-hover rounded 
              ${selectedVenueId === '' ? 'text-hover bg-light-200' : 'text-typography-dark'}`
            }
            role="button"
            onClick={() => dispatch(selectVenue({
              userId: user.id,
              selectedVenueId: '',
              selectedVenueName: getMessage('navigation.allVenues'),
            }))}
            tabIndex={0}
            onKeyPress={(e) => handleKeySelect(e, {
              userId: user.id,
              selectedVenueId: '',
              selectedVenueName: getMessage('navigation.allVenues'),
            })}
          >
            {getMessage('navigation.allVenues')}
          </div>
          )}
      </div>
      <div className="px-7">
        <div
          className="justify-around items-center border-t cursor-pointer w-full block text-typography-dark text-text-lg-medium"
          role="button"
          tabIndex={0}
          onClick={() => logout(null)}
          onKeyUp={(e) => handleKeyLogout(e)}
        >
          <span className="py-6 flex group hover:underline hover:text-main">
            <LogoutIcon className="inline mr-4 leading-7 h-6 w-6 group-hover:text-main" />
            <p className="group-hover:text-main">{getMessage('logout')}</p>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UserNavigation;
