import * as React from 'react';
import { useEffect, PropsWithChildren, useRef } from 'react';
import Popup from '../Popup/Popup';
import Button from '../Button/Button';
import useMessages from '../../hooks/useMessages';

interface Props {
  isOpen: boolean,
  onAccept: () => void,
  onClose: () => void,
  acceptButtonText?: string,
  closeButtonText?: string,
  isAcceptButtonDisabled?: boolean,
  submitButton?: boolean,
  className?: string,
  preventCloseOnAccept?: boolean,
  focusOnBtn?: boolean,
}

const Dialog = ({
  isOpen, onAccept, onClose, acceptButtonText,
  closeButtonText, isAcceptButtonDisabled, children,
  submitButton, className, preventCloseOnAccept, focusOnBtn,
}: PropsWithChildren<Props>) => {
  const getMessage = useMessages();
  const submitBtnRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && focusOnBtn) submitBtnRef.current?.focus();
  }, [isOpen]);

  return (
    <Popup isOpen={isOpen}>
      <div className={`flex flex-col items-center w-full ${className}`}>
        {children}
      </div>
      <div className="flex flex-col items-center w-full">
        <Button
          className="mobile:w-full w-1/2 mb-4"
          styleType="primary"
          type={submitButton ? 'submit' : 'button'}
          ref={submitBtnRef}
          disabled={isAcceptButtonDisabled}
          onClick={() => {
            onAccept();
            if (!preventCloseOnAccept) {
              onClose();
            }
          }}
        >
          {acceptButtonText || getMessage('confirm')}
        </Button>
        <Button
          className="mobile:w-full w-1/2"
          styleType="secondary"
          onClick={() => onClose()}
        >
          {closeButtonText || getMessage('cancel')}
        </Button>
      </div>
    </Popup>
  );
};

export default Dialog;
