import {
  ApiCompanyDetailsResponse,
  ApiCompanySettingsResponse,
  FormCompanyDetails,
  CompanySettings,
  CompanyData,
  CompanyFormInputs,
  CompanyAttachment,
  MailServerConfig, ApiMailServerConfigResponse, MailServerConfigFormInputs, ApiPutMailServerConfig,
} from './companyTypes';

export const translateCompanySettings = (apiCompanySettings: ApiCompanySettingsResponse): CompanySettings => ({
  colorPalette: apiCompanySettings.settings.color_palette,
  homeownerAssociationsMode: apiCompanySettings.settings.hoa_mode,
  resolutionsModuleEnabled: apiCompanySettings.settings.resolutions_enabled,
  settlementsModuleEnabled: apiCompanySettings.settings.settlements_enabled,
  paymentsModuleEnabled: apiCompanySettings.settings.payments_enabled,
  ticketsModuleEnabled: apiCompanySettings.settings.tickets_enabled,
  metersModuleEnabled: apiCompanySettings.settings.meters_enabled,
  documentsModuleEnabled: apiCompanySettings.settings.documents_enabled,
});

export const translateCompanyData = ({ details, attachments }: ApiCompanyDetailsResponse): CompanyData => ({
  name: details.name,
  streetAddress: details.street_address,
  postalCode: details.postcode,
  city: details.city,
  phone: details.phone,
  website: details.website,
  logo: attachments.logo,
  backgroundImage: attachments.background_photo,
  regulations: attachments.regulations,
});

export const translateCompanyDetailsToApi = ({
  name, streetAddress, postalCode, city, phone, website,
}: CompanyFormInputs): FormCompanyDetails => ({
  name,
  street_address: streetAddress,
  postcode: postalCode,
  city,
  phone,
  website,
});

const appendCorrectFile = async (form: FormData, id: string, newFile: FileList | undefined, oldFile: CompanyAttachment | undefined) => {
  if ((!newFile || newFile.length === 0) && oldFile) {
    await fetch(oldFile.path)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], oldFile.filename || '', { type: oldFile.content_type }))
      .then((logoFile) => {
        form.append(id, logoFile);
      });
  } else if (newFile && newFile.length > 0) {
    form.append(id, newFile[0]);
  }
};

export const translateCompanyAttachmentsToApi = async (data: CompanyFormInputs): Promise<FormData> => {
  const form = new FormData();

  await appendCorrectFile(form, 'logo', data.logo, data.oldLogo);
  await appendCorrectFile(form, 'background_photo', data.backgroundImage, data.oldBackgroundImage);
  await appendCorrectFile(form, 'regulations', data.regulations, data.oldRegulations);

  return form;
};

export const translateMailServerConfig = (ApiMailServerConfig: ApiMailServerConfigResponse): MailServerConfig => {
  const {
    user_name: userName, from_email: fromEmail, communication_encryption: communicationEncryption, ...rest
  } = ApiMailServerConfig.mail_server_config;
  return ({
    ...rest,
    userName,
    fromEmail,
    communicationEncryption,
  });
};

export const translateMailServerConfigToApi = (data: MailServerConfigFormInputs): ApiPutMailServerConfig => {
  const {
    userName, fromEmail, communicationEncryption, password, ...rest
  } = data;

  const mailServerConfigInputsData = {
    ...rest,
    user_name: userName,
    from_email: fromEmail,
    communication_encryption: communicationEncryption,
  };

  if (password && password.length > 0) {
    return ({
      ...mailServerConfigInputsData,
      password,
    });
  }
  return mailServerConfigInputsData;
};
