import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppSelector } from '../../hooks/reduxHooks';
import useMessages from '../../hooks/useMessages';
import useFilters from '../../hooks/useFilters';
import { usePermissions } from '../../hooks/usePermissions';
import Button from '../../components/Button/Button';
import EmployeeTicketsList from './TicketsList/EmployeeTicketsList/EmployeeTicketsList';
import PageHeader from '../../components/Layout/PageHeader';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Layout from '../../components/Layout/Layout';
import { FormInput } from '../../components/FormInputs';
import EmployeeTicketsFilters from './TicketsFilters/EmployeeTicketsFilters/EmployeeTicketsFilters';
import FiltersIcon from '../../icons/filters.svg';
import PlusIcon from '../../icons/plus.svg';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';

interface TicketsFilterInputs {
  number_title_search?: string | '',
}
interface EmployeeTicketsPageProps {
  tab?: 'me' | 'none',
}

const EmployeeTicketsPage = ({ tab }: EmployeeTicketsPageProps) => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { hasPermission, checkPermission } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'employeeTicketsTable'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);
  const { saveFilterValues, filterValues } = useFilters<TicketsFilterInputs>('employeeTicketsTable');
  const methods = useForm<TicketsFilterInputs>({
    defaultValues: {
      number_title_search: filterValues?.number_title_search || '',
    },
  });

  useEffect(() => {
    methods.watch(
      (filters) => {
        saveFilterValues(filters);
      },
    );
  }, [methods.watch]);

  const tabs: TabElement[] = useMemo(() => {
    if (hasPermission('tickets_add')) {
      return [
        {
          label: getMessage('tabs.tickets.all'),
          path: '/zgloszenia',
        },
        {
          label: getMessage('tabs.tickets.mine'),
          path: '/zgloszenia/moje',
        }, {
          label: getMessage('tabs.tickets.unassigned'),
          path: '/zgloszenia/nieprzypisane',
        },
      ];
    }

    return [];
  }, [hasPermission]);

  checkPermission('tickets_add');

  return (
    <Layout currentPage="tickets">
      <PageHeader pageTitle={getMessage('local.tickets.ticketsList')} currentPage="tickets">
        {!filtersOpen && (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <FormProvider {...methods}>
            <FormInput
              className="col-span-2"
              id="number_title_search"
              type="search"
              inputClassName="w-full my-0"
              placeholder={getMessage('filters.search')}
              ariaLabel="Szukaj zgłoszenia"
            />
          </FormProvider>
        )}
        <Button
          Icon={FiltersIcon}
          styleType="secondary"
          size="small"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="mx-3 py-1 appearance-none"
        >
          {getMessage('filters')}
        </Button>
        <Button
          onClick={() => navigate('/zgloszenia/nowe')}
          className="py-1"
          size="small"
          Icon={PlusIcon}
        >
          {getMessage('local.tickets.newTicket')}
        </Button>
      </PageHeader>
      <>
        <Tabs tabElements={tabs} />
        {filtersOpen && (<EmployeeTicketsFilters />)}
        <ContentWrapper>
          <EmployeeTicketsList prefilter={tab} />
        </ContentWrapper>
      </>
    </Layout>
  );
};

export default EmployeeTicketsPage;
