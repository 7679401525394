import { rest } from 'msw';

const profile = {
  full_name: 'Antoni Mock',
  login: 'test@example.org',
  external_id: 'test001',
  profile: {
    correspondence_email: 'jakis@email.test',
    phone: '600-600-600',
    address_street: 'ulica 50/50',
    address_postal_code: '05-830',
    address_city: 'Pruszków',
    email_agreement: true,
  },
};

const updateProfile = (postData) => {
  profile.profile = postData;
};

export const profileMocks = [
  rest.get('/users/accounts/profile', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(profile),
  )),

  rest.post('/users/accounts/profile', (req, res, ctx) => {
    req.json().then((postData) => {
      updateProfile(postData);
    });
    return res(
      ctx.status(200),
    );
  }),
];
