const capitalize = (string: string): string => {
  const lowerCased = string.toLowerCase();
  return lowerCased.toLowerCase()[0].toUpperCase() + lowerCased.slice(1);
};

export const formatNumber = (value: string | number): string => {
  const val = typeof value === 'number' ? value.toString() : value;

  return parseFloat(val?.replace(/,/, '.')).toLocaleString('pl-PL', {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 10, // for meter readings
  });
};

export const formatBankAccount = (value: string): string => {
  let formatted = value.slice(0, 2);

  for (let i = 2; i < value.length; i += 4) {
    formatted = `${formatted} ${value.slice(i, i + 4)}`;
  }

  return formatted;
};

export default capitalize;
