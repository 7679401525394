import * as React from 'react';
import { Link } from 'react-router-dom';
import { Permission } from '../../../../utils/generated/permissionsTypes';
import { ModuleName, usePermissions } from '../../../hooks/usePermissions';

interface Props {
  to: string,
  label: string,
  selected: boolean,
  permissionNeeded?: Permission[],
  module?: ModuleName,
}

const NavigationSubItem = ({
  to, label, selected, permissionNeeded, module,
}: Props) => {
  const { hasAnyPermission, isModuleEnabled } = usePermissions();
  if (permissionNeeded && !hasAnyPermission(permissionNeeded)) {
    return null;
  }
  if (module && !isModuleEnabled(module)) {
    return null;
  }

  return (
    <li>
      <Link
        to={to}
        className={`relative flex flex-row items-center h-9 pl-12 pr-6 
        hover:text-main hover:cursor-pointer hover:font-semibold
        ${selected ? 'text-main font-semibold' : 'text-typography-dark'}`}
        tabIndex={0}
      >
        <span className={`ml-2 text-text-lg-medium tracking-wide truncate ${selected ? 'font-semibold' : ''}`}>{label}</span>
      </Link>
    </li>
  );
};

export default NavigationSubItem;
