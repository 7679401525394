import * as React from 'react';
import { NavigationRoutes } from '../Layout/Layout';
import Navigation from './Navigation';

interface Props {
  currentPage?: NavigationRoutes,
}

const Sidebar = ({ currentPage }: Props) => (
  <nav className="hidden lg:flex flex-col w-64 bg-background-white h-full border-r text-gray-800 flex-shrink-0">
    <Navigation currentPage={currentPage} />
  </nav>
);

export default Sidebar;
