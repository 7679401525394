import { Permission } from '../../../../utils/generated/permissionsTypes';
import { PaginationMetadata } from '../../paginationTypes';
import { RoleType } from '../access/accessTypes';
import { ColorPalette } from '../company/companyTypes';

export interface ApiUserProfile {
  full_name: string,
  login: string,
  external_id: string,
  email: string,
  profile?: {
    correspondence_email: string,
    phone: string,
    address_street: string,
    address_postal_code: string,
    address_city: string,
    email_agreement: boolean,
  }
}

export interface ApiPostUserProfile {
  correspondence_email?: string,
  email: string,
  phone?: string,
  address_street?: string,
  address_postal_code?: string,
  address_city?: string,
  email_agreement?: boolean,
  login: string,
}

export const activityCategory = [
  'meters', 'authentication', 'settlements', 'profiles', 'resolutions', 'tasks', 'tickets', 'residency_amendments', 'announcements',
] as const;
export type ApiActivityLogCategory = typeof activityCategory[number];

export type EmployeeApiEvent = {
  created_at: string,
  description: string,
  category: ApiActivityLogCategory,
  url: string,
};

export type TenantApiEvent = EmployeeApiEvent & {
  venue_address?: string,
};

export type AdminApiEvent = Pick<TenantApiEvent, Exclude<keyof TenantApiEvent, 'url'>> & {
  account_external_id?: string,
  account_full_name?: string,
  account_roles?: [],
};

export interface ApiActivityLogResponse {
  events: EmployeeApiEvent[] | TenantApiEvent[] | AdminApiEvent[],
  metadata: PaginationMetadata,
}

export interface Event {
  date: string,
  description: string,
  menuOption: string,
  venue?: string,
  externalId?: string,
  fullName?: string,
  url?: string,
  roles?: [],
}

export interface ActivityLog {
  metadata: PaginationMetadata,
  events: Event[],
}

export interface ApiMe {
  me: {
    full_name: string,
    login: string,
    roles: ApiUserRole[],
    id: string,
    permissions?: Permission[],
    all_permissions?: Permission[],
    tenant?: ApiTenant,
    profile?: ApiUserProfileData,
  }
}

export interface ApiUserRole {
  id: string,
  default: boolean,
  name: 'string',
  role_type: RoleType,
}

export interface ApiTenant {
  email: string,
  venues: ApiVenue[],
}

export interface ApiVenue {
  id: string,
  address: string,
  code: string,
  main_tenant: boolean,
}

export interface UserAccount {
  id: string,
  fullName: string,
  login: string,
  roles: UserRole[],
  permissions?: Permission[],
  allPermissions?: Permission[],
  tenant?: Tenant,
  colorPalette?: ColorPalette,
}

export interface UserRole {
  id: string,
  isDefault: boolean,
  name: string,
  roleType: RoleType,
}

export interface Tenant {
  email: string,
  venues: Venue[],
}

export interface Venue {
  id: string,
  address: string,
  code: string,
  isMainTenant: boolean,
}

export interface UserProfileData {
  profileId: string,
  profileName: string,
  profileEmail: string,
  profileCorrespondenceEmail?: string,
  profilePassword: string,
  profilePhoneNumber?: string,
  profileAddressStreet?: string,
  profileAddressPostalCode?: string,
  profileAddressCity?: string,
  profileEmailAgreement?: boolean,
  profileLogin: string,
}

export interface ApiUserAccountsResponse {
  accounts: ApiUserAccount[],
  metadata: PaginationMetadata,
}

export interface ApiUserAccount {
  correspondence_email: string
  email: string
  email_agreement: string
  external_id: string
  full_name: string
  id: string
  login: string
  roles: string[]
}

export interface Account {
  correspondenceEmail: string
  email: string
  emailAgreement: string
  externalId: string
  fullName: string
  id: string
  login: string
  roles: string[]
}

export interface ApiUserProfileData {
  correspondence_email: string,
  phone: string,
  address_street: string,
  address_postal_code: string,
  address_city: string,
  email_agreement: boolean,
  color_palette?: ColorPalette,
}

export interface ApiAccountResponse {
  account: {
    active: boolean,
    email: string,
    external_id: string,
    full_name: string,
    id: string,
    login: string,
    login_option: string,
    permissions: Permission[],
    profile?: ApiUserProfileData,
    roles: ApiRole[],
    access_groups: {
      'tenants/homeowner_associations': AccessGroup[],
      'tenants/buildings': AccessGroup[],
      'tenants/estates': AccessGroup[],
      'tickets/journals': AccessGroup[]
    },
  }
}

export interface FullUserAccount {
  accessGroups: {
    'homeownerAssociations': AccessGroup[],
    'buildings': AccessGroup[],
    'estates': AccessGroup[],
    'journals': AccessGroup[]
  },
  active: boolean,
  email: string,
  externalId: string,
  fullName: string,
  id: string,
  login: string,
  loginOption: string,
  correspondenceEmail?: string,
  phone?: string,
  addressStreet?: string,
  addressPostalCode?: string,
  addressCity?: string,
  emailAgreement?: boolean,
  roles: Role[],
  permissions: Permission[],
}

export interface Accounts {
  metadata: PaginationMetadata,
  accounts: Account[],
}
export interface ApiRole {
  id: string,
  name: string,
  permissions: Permission[],
  role_type: RoleType | undefined,
  default: boolean,
}

export interface ApiRoles {
  roles: ApiRole[],
}

export interface Role {
  id: string,
  name: string,
  permissions: Permission[],
  isDefault: boolean,
  roleType?: RoleType
}

export interface ApiPostUserAccount {
  email: string,
  full_name: string,
  login: string,
  external_id: string,
  phone: string,
  role_ids: string[],
  access_groups: {
    'tenants/homeowner_associations': string[],
    'tenants/buildings': string[],
    'tenants/estates': string[],
    'tickets/journals': string[],
  },
}

export interface ApiChangeUserActivity {
  active: boolean,
  id: string,
}

export interface ApiPutUserAccount {
  id: string,
  email: string,
  full_name: string,
  login: string,
  active: boolean,
  phone: string,
  role_ids: string[],
  access_groups: {
    'tenants/homeowner_associations': string[],
    'tenants/buildings': string[],
    'tenants/estates': string[],
    'tickets/journals': string[],
  },
}

export interface ApiAccessGroupResponse {
  access_groups: AccessGroup[],
}

export interface AccessGroup {
  id: string,
  name: string,
}

export interface NewPasswordData {
  confirmNewPassword: string,
  newPassword: string,
}

export interface MyNewPasswordData extends NewPasswordData {
  oldPassword: string,
}

export interface ApiPostNewPassword {
  password: string,
  password_confirmation: string,
}
export interface ApiPostMyNewPassword extends ApiPostNewPassword {
  old_password: string,
}

export interface ChangeUserFormData {
  id: string,
  email: string,
  fullName: string,
  login: string,
  active: boolean,
  phone: string,
  roleIds: string[],
  homeownerAssociationIds: string[]
  buildingsIds: string[]
  estatesIds: string[]
  journalsIds: string[]
}

export interface CreateUserFormData {
  email: string,
  fullName: string,
  externalId: string,
  login: string,
  phone: string,
  roleIds: string[],
  homeownerAssociationIds: string[]
  buildingsIds: string[]
  estatesIds: string[]
  journalsIds: string[]
}

export interface ColorPaletteFormData {
  colorPalette?: ColorPalette | 'default',
}
