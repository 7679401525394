import * as React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useGetResolutionQuery, usePostContentAcceptedMutation } from '../../../api/appApi';
import useMessages from '../../../hooks/useMessages';
import Card from '../../../components/Card/Card';
import PageHeader from '../../../components/Layout/PageHeader';
import PdfPreviewer from '../../../components/PdfPreviewer/PdfPreviewer';
import { ResolutionData } from '../../../api/modules/resolutions/resolutionsTypes';
import Button from '../../../components/Button/Button';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import DownloadAttachment from '../../../components/AttachmentPreview/DownloadAttachment/DownloadAttachment';
import { usePermissions } from '../../../hooks/usePermissions';
import {
  mobile, tablet, PDF_PREVIEW_WIDTH_DESKTOP, PDF_PREVIEW_WIDTH_MOBILE, PDF_PREVIEW_WIDTH_TABLET,
} from '../../../../utils/breakpoints';
import { FormNotice } from '../../../components/FormNotice/FormNotice';

const FilePreview = ({
  contentType, path, filename, width,
}: {
  contentType: string, path: string, filename: string, width: number
}) => {
  switch (contentType) {
    case 'application/pdf':
      return <PdfPreviewer source={path} width={width} />;
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
      return <img src={path} alt={filename} />;
    default:
      return <DownloadAttachment path={path} filename={filename} />;
  }
};

const ResolutionAttachmentPreview = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { id, index } = useParams();
  const { checkModuleEnabled } = usePermissions();

  checkModuleEnabled('resolutions');
  if (!id || !index) return null;
  const { data, isSuccess } = useGetResolutionQuery(id, { skip: !id });
  const [postContentAcceptedMutation, result] = usePostContentAcceptedMutation();
  const { contentAcceptedAt, attachments, active } = isSuccess ? data : {} as ResolutionData;
  const length = attachments?.length;
  const indexAsNumber = Number(index);
  const isMobile = useMediaQuery({ query: mobile.raw });
  const isTablet = useMediaQuery({ query: tablet.raw });

  if (!attachments) return null;

  const { contentType, path, filename } = attachments[indexAsNumber];
  const isContentAccepted = contentAcceptedAt !== null;

  let pdfWidth = PDF_PREVIEW_WIDTH_DESKTOP;
  if (isMobile) pdfWidth = PDF_PREVIEW_WIDTH_MOBILE;
  if (isTablet) pdfWidth = PDF_PREVIEW_WIDTH_TABLET;

  return (
    <Layout currentPage="resolutions">
      <PageHeader pageTitle={getMessage('resolutions.voting')} currentPage="resolutions" path={`/uchwaly/${id}`} />
      <ContentWrapper>
        <Card className="col-span-full">
          <div className="mobile:p-0 w-fit m-auto" id="fileContainer">
            <FilePreview contentType={contentType} path={path} filename={filename} width={pdfWidth} />
          </div>
          <div className="w-full">
            { (indexAsNumber !== 0) && (
            <Link to={`/uchwaly/${id}/akceptuj/${indexAsNumber - 1}`} className="block text-left text-text-lg-semibold text-typography-dark hover:text-main hover:underline">
              &#60;
              {' '}
              {getMessage('resolutions.resolution.previous')}
            </Link>
            )}
            { (indexAsNumber !== length - 1)
              ? (
                <Link to={`/uchwaly/${id}/akceptuj/${indexAsNumber + 1}`} className="block text-right text-text-lg-semibold text-typography-dark hover:text-main hover:underline">
                  {getMessage('resolutions.resolution.next')}
                  {' '}
                  &#62;
                </Link>
              )
              : (
                <div className="mt-6 pt-6 border-t">
                  {result.isError
                    /* @ts-ignore */
                    && <FormNotice noticeClassName="col-span-full mb-6" type="error" message={result.error.data?.errors || getMessage('form.error')} />}
                  <div className="flex justify-between">
                    <label htmlFor="contentAccepted" className={`text-title-medium ${!active && 'text-gray-500'} hover:underline hover:text-hover hover:cursor-pointer`}>
                      <input
                        type="checkbox"
                        id="contentAccepted"
                        className="mr-3 hover:cursor-pointer"
                        onChange={() => postContentAcceptedMutation(id)}
                        checked={isContentAccepted}
                        disabled={isContentAccepted || !active}
                      />
                      {getMessage('resolutions.resolution.acceptedContent')}
                    </label>
                    {active && (
                    <Button
                      disabled={!isContentAccepted}
                      styleType="primary"
                      onClick={() => navigate(`/uchwaly/${id}`)}
                    >
                      {getMessage('resolutions.resolution.goToVoting')}
                    </Button>
                    )}
                  </div>
                </div>
              )}
          </div>
        </Card>
      </ContentWrapper>
    </Layout>
  );
};

export default ResolutionAttachmentPreview;
