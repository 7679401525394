import * as React from 'react';
import { useState } from 'react';
import useMessages from '../../hooks/useMessages';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import PageHeader from '../../components/Layout/PageHeader';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Layout from '../../components/Layout/Layout';
import ProfileForm from './UserForm/ProfileForm';
import ActivityLog from './ActivityLog/ActivityLog';
import Button from '../../components/Button/Button';
import { useAppSelector } from '../../hooks/reduxHooks';
import ActivityLogFilters from './ActivityLog/ActivityLogFilters';
import FiltersIcon from '../../icons/filters.svg';
import SettingsForm from './SettingsForm/SettingsForm';

interface Props {
  tab: 'profile' | 'settings' | 'activityLog'
}

const ProfilePage = ({ tab } : Props) => {
  const getMessage = useMessages();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'myActivityLog'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);
  const tabs: TabElement[] = [
    {
      label: getMessage('local.profile.user'),
      path: '/profil',
    },
    {
      label: getMessage('local.profile.theme'),
      path: '/profil/wyglad',
    },
    {
      label: getMessage('local.profile.activityLog'),
      path: '/profil/rejestr_zdarzen',
    },
  ];

  return (
    <Layout currentPage="profile">
      <PageHeader pageTitle={getMessage('navigation.profile')} currentPage="profile">
        {tab === 'activityLog' && (
        <Button
          styleType="secondary"
          size="small"
          Icon={FiltersIcon}
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="my-6"
        >
          {getMessage('filters')}
        </Button>
        )}
      </PageHeader>
      <Tabs tabElements={tabs} />
      {tab === 'activityLog' && filtersOpen && <ActivityLogFilters />}
      <ContentWrapper>
        {tab === 'profile' && <ProfileForm />}
        {tab === 'settings' && <SettingsForm /> }
        {tab === 'activityLog' && <ActivityLog />}
      </ContentWrapper>
    </Layout>
  );
};

export default ProfilePage;
