import * as React from 'react';
import { PropsWithChildren } from 'react';
import classnames from 'classnames';

interface FormNoticeProps {
  type: string,
  noticeClassName?: string,
  message: string[] | string,
}

export const FormNotice = ({ type, message, noticeClassName }: PropsWithChildren<FormNoticeProps>) => {
  const errorStyle = 'px-2 py-1 mb-2 bg-interactions-error-bg text-text-md-semibold text-interactions-error-text text-center rounded';
  const successStyle = 'px-2 py-1 mb-2 bg-interactions-success-bg text-text-md-semibold text-interactions-success-text text-center rounded';

  return (
    <div className={classnames(type === 'error' ? errorStyle : successStyle, noticeClassName)}>
      {Array.isArray(message)
        ? message.map((messageItem) => <p>{messageItem}</p>)
        : message}
    </div>
  );
};
