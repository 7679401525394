import * as React from 'react';
import { useIntl } from 'react-intl';
import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';
import UserNavigation from './UserNavigation/UserNavigation';
import MainNavigation from './MainNavigation/MainNavigation';
import { useGetCompanyDataQuery, useGetFullUserAccountQuery } from '../../api/appApi';
import { useAppSelector } from '../../hooks/reduxHooks';
import useClickOutside from '../../hooks/useClickOutside';
import { NavigationRoutes } from '../Layout/Layout';
import Spinner from '../Spinner/Spinner';

interface Props {
  currentPage?: NavigationRoutes,
}

const Navigation = ({ currentPage }: Props) => {
  const intl = useIntl();
  const { data: companyDetails, isSuccess: companyFetched } = useGetCompanyDataQuery();
  const { data: user, isSuccess: userFetched } = useGetFullUserAccountQuery();
  const selectedVenueName = useAppSelector((state) => state.app.selectedVenueName);

  const [userNavigationOpen, setUserNavigationOpen] = useState(false);
  const userNavigationRef = useRef(null);
  const userVenueRef = useRef(null);

  const hasVenues = useMemo(() => !!user?.tenant?.venues && user.tenant.venues.length > 0, [user]);

  const toggleOpen = () => {
    setUserNavigationOpen((open) => !open);
  };

  useClickOutside(userNavigationRef, () => setUserNavigationOpen(false), [userVenueRef]);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleOpen();
    }
  };

  return (
    userFetched ? (
      <>
        <a className="absolute -top-20 p-2 text-typography-black text-text-lg-semibold focus:top-0 focus:block focus:bg-background-light" href="#main">Przejdź do treści</a>
        <Link className="hidden lg:flex items-center justify-center h-24 border-b mx-2 sm:mx-3 md:mx-7" to="/" aria-label="Przejdź do strony głównej">
          {companyFetched && companyDetails.logo && (
            <img
              src={companyDetails.logo?.path}
              alt={companyDetails.name || intl.formatMessage({ id: 'navigation.header' })}
              className="w-14 mr-4"
            />
          )}
          <p className="text-typography-black text-text-lg-semibold">{companyFetched && companyDetails.name ? companyDetails.name : intl.formatMessage({ id: 'navigation.header' })}</p>
        </Link>
        <div className="flex flex-col items-start h-20 justify-center mx-2 sm:mx-3 md:mx-7">
          {hasVenues && (
          <div className="text-typography-dark text-text-md-medium font-light">
            {user.fullName}
          </div>
          )}
          <span
            role="button"
            onKeyUp={(e) => handleKeyUp(e)}
            onClick={toggleOpen}
            tabIndex={0}
            className="inline-block cursor-pointer w-full text-typography-black text-text-lg-semibold hover:text-hover"
            ref={userVenueRef}
          >
            {hasVenues ? selectedVenueName : user.fullName}
            {userNavigationOpen ? <ArrowUp className="inline float-right ml-1 mt-2 hover:text-hover" /> : <ArrowDown className="inline float-right ml-1 mt-2 hover:text-hover" />}
          </span>
        </div>
        <div className="relative">
          <UserNavigation ref={userNavigationRef} isOpen={userNavigationOpen} />
        </div>
        <MainNavigation currentPage={currentPage} />
      </>
    ) : <Spinner className="m-auto mt-6" />
  );
};

export default Navigation;
